<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('products.list.filters') }}</span>
                <span style="float: right">
                    <el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="applyFilters">{{
                        $t('general.apply')
                    }}</el-button>
                </span>
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('reports.orders.status') }}</div>
                    <el-select class="fullWidth" v-model="filters.selectedStatus.value" filterable>
                        <el-option :key="0" :value="0" label="Alegeti un status"></el-option>
                        <el-option
                            :key="item.id"
                            :value="item.id"
                            :label="item.status"
                            v-for="item in statuses"
                        ></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('reports.orders.dateMin') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        value-format="yyyy-MM-dd"
                        v-model="filters.dateMin.value"
                        type="date"
                        :placeholder="$t('reports.orders.placeholderDate')"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('reports.orders.dateMax') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        value-format="yyyy-MM-dd"
                        v-model="filters.dateMax.value"
                        type="date"
                        :placeholder="$t('reports.orders.placeholderDate')"
                    >
                    </el-date-picker>
                </el-col>
            </el-row>
        </el-card>

        <div class="separator"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span style="float: right">
                    <el-button
                        type="info"
                        size="mini"
                        icon="el-icon-document"
                        plain
                        @click.native="downloadOrdersReportPDF"
                        >{{ $t('reports.orders.download-pdf') }}</el-button
                    >
                    <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        plain
                        @click.native="downloadOrdersReport"
                        >{{ $t('reports.orders.download') }}</el-button
                    >
                </span>
            </div>
            <el-table :data="orders" border style="width: 100%">
                <el-table-column prop="orders_id" :label="$t('reports.orders.id')"> </el-table-column>
                <el-table-column prop="customers_name" :label="$t('reports.orders.customers_name')"> </el-table-column>
                <el-table-column prop="status" :label="$t('reports.orders.status')"> </el-table-column>
                <el-table-column prop="serie" :label="$t('reports.orders.serie')"> </el-table-column>
                <el-table-column prop="nr_fact" :label="$t('reports.orders.nr_fact')"> </el-table-column>
                <el-table-column prop="awb" :label="$t('reports.orders.awb')"> </el-table-column>
                <el-table-column prop="payment_method" :label="$t('reports.orders.payment_method')"> </el-table-column>
            </el-table>
            <!--<div class="center" >-->
            <!--<div class="separator"></div>-->
            <!--<simple-pagination ref="sp" :currentpage="page" :pages="pages" @changePage="changePriceDiffPage" vspbutton="vspButton" vspbuttonselected="vspButton-selected" vspbuttonfast="vspButtonFast"></simple-pagination>-->
            <!--</div>-->
        </el-card>
    </div>
</template>

<script>
const Box = () => import('vue-info-box-element');
const SimplePagination = () => import('@celdotro/vue-simplepagination');
import { EventBus } from '@/utils/Bus.js';
import { ordersReport, downloadOrdersReport, downloadOrdersReportPDF } from '@/api/reports';
import { getOrderStatusList } from '@/api/vanzari';

import Vue from 'vue';
import { currentDate, lastMonthDate, nFormatter, _base64ToArrayBuffer } from '@/utils/index';

export default {
    components: {
        Box,
        SimplePagination
    },
    props: ['filterQuery'],
    data() {
        return {
            orders: [],
            statuses: [],
            filters: {
                dateMin: {
                    type: 'select',
                    value: ''
                },
                dateMax: {
                    type: 'select',
                    value: ''
                },
                selectedStatus: {
                    type: 'select',
                    value: 0
                }
            }
        };
    },
    methods: {
        async downloadOrdersReport() {
            try {
                if (this.filters.dateMin.value == '') return;
                if (this.filters.dateMax.value == '') return;
                const res = await downloadOrdersReport({
                    dateMin: this.filters.dateMin.value,
                    dateMax: this.filters.dateMax.value,
                    selectedStatus: this.filters.selectedStatus.value
                });

                res.message = unescape(encodeURIComponent(res.message));
                const text = _base64ToArrayBuffer(res.message);
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([text], {
                        type: 'application/pdf'
                    })
                );
                a.download = 'raport_comenzi.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                if (typeof this.updateQuery === 'function') this.updateQuery();
            } catch (e) {
                console.log(e);
            }
        },
        async downloadOrdersReportPDF() {
            try {
                if (this.filters.dateMin.value == '') return;
                if (this.filters.dateMax.value == '') return;
                const res = await downloadOrdersReportPDF({
                    dateMin: this.filters.dateMin.value,
                    dateMax: this.filters.dateMax.value,
                    selectedStatus: this.filters.selectedStatus.value
                });
                if (typeof res.message.data !== 'undefined' && res.message.data !== '') {
                    const linkSource = `data:application/pdf;base64,${res.message.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = res.message.name + '.pdf';

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();

                    return false;
                }
            } catch (e) {
                console.log(e);
            }
        },
        formatDate(date) {
            const d = date;
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        async applyFilters() {
            try {
                if (this.filters.dateMin.value == '') return;
                if (this.filters.dateMax.value == '') return;
                const res = await ordersReport({
                    dateMin: this.filters.dateMin.value,
                    dateMax: this.filters.dateMax.value,
                    selectedStatus: this.filters.selectedStatus.value
                });
                this.orders = res.message.orders;

                if (typeof this.updateQuery === 'function') this.updateQuery();
            } catch (e) {
                console.log(e);
            }
        },
        async getStatuses() {
            try {
                const res = await getOrderStatusList();

                this.statuses = [];
                const allowedStatuses = [1, 2, 21, 25, 11, 9, 24];
                for (const e in res.message) {
                    if (allowedStatuses.indexOf(parseInt(e)) === -1) continue;
                    this.statuses.push({ id: String(e), status: res.message[e] });
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    computed: {},
    watch: {},
    mounted() {
        this.getStatuses();
    },
    beforeDestroy() {
        // EventBus.$off('closeModal')
    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import './src/styles/modules/reports.scss';
</style>
